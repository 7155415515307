@import "~bootstrap-scss/bootstrap.scss";
@import "color";
@import "vendors/themify.scss";
@import "vendors/whether-icon.scss";
@import "vendors/icofont.scss";
@import "vendors/flag-icon.scss";
@import "vendors/icoicon/icons";
@import "vendors/fontawesome.scss";
@import "vendors/animate/animate";
@import "vendors/sticky/sticky";
@import "vendors/todo/todo";

// Datepicker
@import "~react-datepicker/dist/react-datepicker.css";

// Timepicker
// @import "~react-times/css/material/default.css";
// @import "~react-times/css/classic/default.css";

// Typeahead
@import "~react-bootstrap-typeahead/css/Typeahead.css";

//  Dropzone
@import "~react-dropzone-uploader/dist/styles.css";

// Drag&Drop
@import "~react-dragula/dist/dragula.css";

@import '@asseinfo/react-kanban/dist/styles.css';

// ReactToastify
@import "~react-toastify/dist/ReactToastify.css";

//  ImageCrop
@import "~react-image-crop/dist/ReactCrop.css";

//  calendar
@import "~react-big-calendar/lib/css/react-big-calendar.css";
// react-big-calendar/lib/css/react-big-calendar.css
// @import "~sweetalert2/src/sweetalert2.scss";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css";

//  Timeline
@import "~react-vertical-timeline-component/style.min.css";

// Mde EDitor
@import "~easymde/dist/easymde.min.css";

// chat emoji
@import "~emoji-mart/css/emoji-mart.css";

// Map
@import "~leaflet/dist/leaflet.css";

// Image gallery
@import "~react-image-lightbox/style.css";

//image upload
@import "~react-perfect-scrollbar/dist/css/styles.css";

.text-right {
  text-align: right;
}

.mr-5 {
  margin-right: 5px;
}

table .table-action-button {
  text-align: right;
}

table .table-action-button i {
  padding: 10px 12px;
  margin-left: 3px;
  font-size: 21px !important;
  color: white;
  background: grey;
}

.action-float {
  position: fixed;
  bottom: 15px;
  right: 15px;
  font-size: 30px;
  z-index: 1;

  .dropup-basic,
  .loader-box,
  button.dropbtn,
  span.bg-success {
    display: inline-block;
  }

  .loader-box {
    margin-right: 15px;
  }

  span.bg-success {
    border-radius: 100%;
    width: 50px;
    text-align: center;
    cursor: pointer;

    i {
      padding: 10px;
    }
  }

  .dropup-basic .dropup .dropup-content {
    position: fixed;
    right: 75px;
    bottom: 63px;
    left: unset;
  }

  button.dropbtn {
    margin-right: 10px;
    border-radius: 100%;
    width: 45px;
    text-align: center;
    padding: 0px !important;
    cursor: pointer;

    i {
      font-size: 20px;
      padding: 13px 0px;
    }
  }
}

.lbl-align-self-end {
  align-self: flex-end;
}

.tap-top {
  visibility: hidden;
}

.section-blur {
  position: relative;
}

.section-blur-overlay {
  position: absolute;
  top: -10px;
  right: -15px;
  left: -15px;
  bottom: -10px;
  z-index: 1;
  backdrop-filter: blur(3px);

  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 15px;
}